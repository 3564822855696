import Cookies from "js-cookie";
import axios from "axios";
import store from "@/plugins/store";
const API_BASE_URL = store.state.api_backend;

const login = (user,pass) => {
  return axios.post(
      API_BASE_URL+"api/login",
    {
      email: user,
      password: pass,
    })
}
const setUserToken = (userLogged) => {
  Cookies.set("userLogged", userLogged);
}
const getUserToken = ()=> {
  return Cookies.get("userLogged");
}
const deleteUserToken = () =>{
  Cookies.remove('userLogged');
}

export default {
  login,
  setUserToken,
  getUserToken,
  deleteUserToken
}
