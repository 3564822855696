import Vue from 'vue'
import App from './App.vue'

import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './plugins/store'

import moment from 'moment'

import VueMeta from 'vue-meta'


Vue.use(VueAxios, axios)
Vue.use(VueMeta)

Vue.config.productionTip = false

Vue.prototype.$moment = moment

Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
  value = value.toString();

    if (value.length <= size) {
        return value;
    }
  return value.substr(0, size) + '...';
});
Vue.filter('text_capitalize', function (str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
});
Vue.filter('money', function (value) {
    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2);
    return numberFormat2.format(value)
});

Vue.prototype.isMobile = () => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
}


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


