import Vue from 'vue'
import VueRouter from 'vue-router'
import VuePageTransition from 'vue-page-transition'
import store from "@/plugins/store";
import AuthService from "@/services/auth.service";
import AdminLayout from "@/views/layout/AdminLayout";

Vue.use(VuePageTransition)
Vue.use(VueRouter)

const routes = [
    //desktop
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/desktop/Home.vue')
  },
  {
    path: '/about-event',
    name: 'AboutEvent',
    component: () => import('../views/desktop/AboutEvent.vue')
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/desktop/Payment.vue')
  },
  {
    path: '/totem',
    name:'TotemHome',
    component: () => import('../views/restaurant/desktop/DesktopMenu.vue')

  },
  {
    path: '/pretotem',
    name:'PreTotem',
    component: () => import('../views/restaurant/desktop/PreTotem.vue')

  },

  //mobile
  {
    path:'/mobile/restaurant/menu',
    name:'MobileMenu',
    component: () => import('../views/restaurant/mobile/MobileMenu'),
    meta: { transition: 'fade-in-left' },
  },
  {
    path:'/mobile/restaurant/details',
    name:'MobileMenuItem',
    component: () => import('../views/restaurant/mobile/ItemDetails'),
    meta: { transition: 'overlay-up' },
  },
  {
    path:'/mobile/restaurant/cart',
    name:'MobileCart',
    component: () => import('../views/restaurant/mobile/Cart')
  },
  {
    path:'/mobile/restaurant/payment',
    name:'MobileCartPayment',
    component: () => import('../views/restaurant/mobile/Payment')
  },
  {
    path:'/mobile/restaurant/details',
    name:'MobileCartDetails',
    component: () => import('../views/restaurant/mobile/Details')
  },
  {
    path: '/mobile/remember',
    name: 'MobileRemember',
    component: () => import('../views/mobile/RememberPage.vue')
  },
  {
    path: '/mobile/details',
    name: 'MobileDetails',
    component: () => import('../views/mobile/Details.vue')
  },
  {
    path: '/mobile/payment',
    name: 'MobilePayment',
    component: () => import('../views/mobile/Payment.vue')
  },
  {
    path: '/restaurant/confirm',
    name: 'ConfirmOrder',
    component: () => import('../views/restaurant/mobile/confirm.vue'),
  },
    // oneview
  {
    path: '/select-table',
    name: 'SelectTable',
    component: () => import('../views/SelectTable.vue'),
  },
  {
    path: '/confirm',
    name: 'ConfirmReservation',
    component: () => import('../views/Confirm.vue'),
  },
  //admin
  {
    path: '/empleados',
    component: AdminLayout,
    children: [
      {
        path:'/',
        name:'AdminDashboard',
        component: () => import('../views/admin/Dashboard'),
      },
      {
        path:'/empleados/login',
        name:'AdminLogin',
        component: () => import('../views/admin/Login'),
      },
      {
        path:'/empleados/event/:id',
        name:'AdminEvent',
        component: () => import('../views/admin/Evento'),
      },
      {
        path:'/empleados/reservaciones/:id',
        name:'AdminSchedule',
        component: () => import('../views/admin/Tickets'),
      },
      {
        path:'/empleados/anfiteatro',
        name:'Anfiteatro',
        component: () => import('../views/admin/Tables'),
      }
    ],
  },
  { path: '/404', name:'not-found', component: () => import('../views/desktop/Home.vue') },
  { path: '*', name:'not-found', redirect: '/404' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  store.commit('setLoading')
  const publicPages = ['/empleados'];
  const authRequired = publicPages.includes(to.path);
  const loggedIn = AuthService.getUserToken();
  if (authRequired && !loggedIn) {
    next('/empleados/login');
  } else {
    next();
  }
})

export default router
