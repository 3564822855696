import Vue from 'vue';
import Vuex from 'vuex'
// import {AmphiEvents} from "@/services/api";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        terminalID: 900000001,
        user: {
            token_type: 'Bearer',
            access_token: '360|owPwADSI5R2UVuQi0IJ6enCh3uxZvzPJUhwF9hgY'
        },
        phoneNumber: null,
        amphiEvents: [],
        actualTotemEvent: null,
        reservation: null,
        loading: true,
        order:null,
        categories: [],
        articles: [],
        relatedItem: false,
        api_backend: 'https://stage.lamarinaresorts.com/'

    },
    mutations: {
        setUser(state, user){
            state.user = user;
        },
        setRelatedItem(state, item){
            state.relatedItem = item;
        },
        setTotemEvent(state, totemEvent){
            state.actualTotemEvent = totemEvent;
        },
        setEvent(state, events){
            events.forEach((event)=>{
                event.price = event.price / 100
            })
            state.amphiEvents = events
        },
        setReservation(state, reservation){
            state.reservation = reservation
        },
        setLoading(state, loading=true){
            state.loading = loading
        },
        setItems(state, items){
            state.articles = items
        },
        setOrder(state, order){
            state.order = order
        },
        setCategories(state, items){
            state.categories = items
        },
        setPhone(state, number){
            state.phoneNumber = number
        },
        setTerminal(state, id){
            state.terminalID = id
        }
    },
    actions: {
        getUser(context, id){
            context.commit('setUser', {id: id})
        },

    },
})
