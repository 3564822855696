<template>
  <div>
    <v-toolbar
        dark
        prominent
        src="/img/coliseo_grande.58a22d93.jpeg"
    >

      <v-toolbar-title>La Marina</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn v-if="$route.name != 'AdminDashboard'" icon @click="$router.go(-1)">
        <v-icon >mdi-keyboard-backspace</v-icon>
      </v-btn>
      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon
                   v-bind="attrs"
                   v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                v-for="(item, index) in menuItems"
                :key="index"
                link
                :to="item.route"

            >
                <v-list-item-title @click="$router.push(item.route)">
                  {{ item.title }}
                </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-btn icon @click="logout">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-toolbar>
    <router-view></router-view>
  </div>
</template>

<script>
import AuthService from "@/services/auth.service";

export default {
  name: "AdminLayout",
  data(){
    return{
      menuItems:[
        {
          title: 'Dashboard',
          route: '/empleados'
        },

      ],
    }
  },
  methods:{
    logout(){
      AuthService.deleteUserToken();
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>